import React, { Component } from "react";
import Siema from "siema";
import Siema2 from "siema";
import Header from "./Header";
import { NavLink } from "react-router-dom";
import { counterUp } from "./scripts/count-up.js";
import { withTranslation, Trans } from "react-i18next";
// import Rodal from "rodal";


class ScrollToTopOnMount extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return null
  }
}

class Home extends Component {
  render() {
    return (
      <main className="home">
        <ScrollToTopOnMount/>   
        <Header />
        <Association />
        <Slider />
        <Process />
        <Counters />
        <Clients />
        <CallToAction />
        {/* <Popup />   */}

      </main>
    );
  }
}


/* class Popup extends Component {

  constructor(props) {
    super(props);
    this.state = { visible: false };
  }

  
  show() {
    this.setState({ visible: true });
  }

  hide() {
    this.setState({ visible: false });
  }

  render() {
    return (
      <div>
        <Rodal visible={this.state.visible} onClose={this.hide.bind(this)}>
        <div id="modal" className="modal">
            <div className="header"> Nowa siedziba PostMaster! </div>
            <div className="content">
              Od 1 sierpnia 2020 roku zmianie uległ adres Centrali PostMaster Sp. z o.o.<br />
              Nowy adres naszego biura to <b>30-722 Kraków, ul. Rybitwy 22</b>.<br />
              Uprzejmie prosimy o kierowanie tradycyjnej korespondencji pod nowy adres.
            </div>
            <div className="footer">
            Wszystkie pozostałe dane kontaktowe, takie jak: adresy e-mailowe, numer stacjonarny do biura i numery komórkowe pozostają bez zmian.
            </div>
          </div>
        </Rodal>
      </div>
    );
  }

  componentDidMount() {
    setTimeout(() => {
      this.show();
    }, 1000);
  }
}
 */

const slide_gora = props => <img {...props} alt="slide_gora" />;
class Association extends Component {
  render() {
    return (
    <section className="slider_gorny">
        {/* <div className="slide_gora">
                  <section className="asso">
                    <a href="https://www.facebook.com/czystaklamka/">
                    <div className="container slider_czysta">
                       <div className="asso_czysta_1">
                            
                                  <img src="./images/czysta.png" alt="" />
                            
                        </div>
                        <div className="asso_czysta_2">
                            <h2 className="h2_mniejszy">
                            <Trans>PostMaster bierze udział w akcji czysta klamka</Trans>
                            </h2>
                            <span>
                            <Trans>Wspólnie dbamy o dobro i bezpieczeństwo nas wszystkich!</Trans>
                            </span>
                        </div>
         

                    </div>
                    </a>
                  </section> 
        </div> */}
          <div className="slide_gora">
                  <section className="asso">
                    <div className="container slider_padding">
                        <div className="asso_div1">
                            <h2>
                            <Trans>PostMaster członkiem SMB</Trans>
                            </h2>
                            <span>
                            <Trans>Wspólnie odnawiamy rynek reklamy bezadresowej w Polsce!</Trans>
                            </span>
                        </div>
                        <div className="asso_div2">
                            <a href="https://smb.pl/dzial/wiedza-dm">
                                  <img src="./images/asso.png" alt="" />
                            </a>
                        </div>

                    </div>
                  </section> 
        </div>
        <div className="slide_gora">
                <section className="asso">
                    <div className="container">
                        <div className="asso_div3">
                            <a href="./images/Direct-Mail-Press-2021.pdf" download>
                                  <img src="./images/baner_DM.gif" alt="" />
                            </a>
                        </div>

                    </div>
                  </section> 
         </div>
        
      </section>
        
        
        
    );
  }
    componentDidMount() {
    function onChangeCallback() {
      this.innerElements.forEach((slide_gora, i) => {
        const addOrRemove = i === this.currentSlide ? "add" : "remove";
        this.innerElements[i].classList[addOrRemove]("show");
      });
    }

    function onInitCallback() {
      setTimeout(() => {
        this.innerElements[0].classList.add("show");
      }, 300);
    }

    const options = {
      selector: ".slider_gorny",
      easing: "cubic-bezier(0.55, 0.16, 0.59, 0.98)",
      duration: 600,
      loop: true,
      onInit: onInitCallback,
      onChange: onChangeCallback
    };

    const mySiema2 = (this.siema2 = new Siema2(options));
    Siema2.prototype.addPagination = function() {
    };

    mySiema2.addPagination();

   setInterval(() => mySiema2.next(), 5000);
    
    
}
}

const Slide = props => <img {...props} alt="slide" />;

class Slider extends Component {
  render() {
    return (
      <div className="slider-wrapper" id="home">
        <section className="slider">
          <div className="slide">
            <Slide src="./images/slide1.jpg" />
            <div className="caption">
              <h2 className="bolder">
                <Trans i18nKey="slide1text1">
                  PostMaster <br />wspiera sprzedaż
                </Trans>
              </h2>
              <h4>
                <Trans i18nKey="slide1text2">
                  Materiał drukowany ma MOC sprzedażową,<br />trzeba tylko umieć
                  ją odkryć.
                </Trans>
              </h4>
              <p>
                <Trans>
                  Tworzymy i wdrażamy rozwiązania, które realnie wspierają
                  sprzedaż.
                </Trans>
              </p>
            </div>
          </div>
          <div className="slide">
            <Slide src="./images/slide2.jpg" />
            <div className="caption">
              <h2 className="bolder">
                <Trans>Myślimy strategicznie</Trans>
              </h2>
              <h4>
                <Trans>
                  Jesteśmy nie tylko ekspertami w dystrybucji, jesteśmy
                  partnerem, który doradza jak najefektywniej wykorzystać ten
                  kanał.
                </Trans>
              </h4>
              <p>
                <Trans>
                  Pokazujemy miejsce komunikacji offline w rozwiązaniach
                  omnichanelowych.
                </Trans>
              </p>
            </div>
          </div>
          <div className="slide">
            <Slide src="./images/slide3.jpg" />
            <div className="caption">
              <h2 className="bolder">
                <Trans>Kreujemy konwersję</Trans>
              </h2>
              <h4>
                <Trans>
                  Pracujemy z insightami Waszych klientów. Tworzymy kreatywną
                  koncepcję kampanii. Opracowujemy mapy – geotargetujemy
                </Trans>
              </h4>
              <p>
                <Trans i18nKey="slide3text3">
                  KREUJEMY KONWERSJĘ NAWET <b>43 %</b> – Sprawdź nas!
                </Trans>
              </p>
            </div>
          </div>
          <div className="slide">
            <Slide src="./images/slide4.jpg" />
            <div className="caption">
              <h2 className="bolder">
                <Trans>Nowa epoka w dystrybucji</Trans>
              </h2>
              <h4>
                <Trans>
                  Rozwijamy system dokumentowania dystrybucji on-line, który
                  umożliwia zarządzanie kolportażem w czasie rzeczywistym oraz
                  bieżące monitorowanie jego jakości już w trakcie trwania
                  procesu.
                </Trans>
              </h4>
            </div>
          </div>
        </section>
        <div className="pagination container">
          <div className="active">
            <h5>
              <Trans i18nKey="slidebutton1text1">
                SKUTECZNIE<br /> WSPIERAMY SPRZEDAŻ
              </Trans>
            </h5>
            <p>
              <Trans>Odkrywamy na nowo potencjał komunikacji offline.</Trans>
            </p>
          </div>
          <div>
            <h5>
              <Trans i18nKey="slidebutton2text1">
                MYŚLIMY<br /> STRATEGICZNIE
              </Trans>
            </h5>
            <p>
              <Trans>
                Tworzymy i dystrybuujemy materiały, realnie wzmacniające siłę
                Waszego przekazu.
              </Trans>
            </p>
          </div>
          <div>
            <h5>
              <Trans i18nKey="slidebutton3text1">
                KREUJEMY<br /> KONWERSJĘ
              </Trans>
            </h5>
            <p>
              <Trans>
                Na bazie wiedzy o tym DO KOGO? O CZYM? I W JAKIEJ FORMIE?
              </Trans>
            </p>
          </div>
          <div>
            <h5>
              <Trans i18nKey="slidebutton4text1">
                NOWA EPOKA<br /> W DYSTRYBUCJI
              </Trans>
            </h5>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    function onChangeCallback() {
      const btnDiv = document.querySelectorAll(".pagination div");
      let btnActive = document.querySelector(".pagination div.active");
      btnActive.classList.remove("active");
      btnDiv[this.currentSlide].classList.add("active");
      this.innerElements.forEach((slide, i) => {
        const addOrRemove = i === this.currentSlide ? "add" : "remove";
        this.innerElements[i].classList[addOrRemove]("show");
      });
    }

    function onInitCallback() {
      setTimeout(() => {
        this.innerElements[0].classList.add("show");
      }, 300);
    }

    const options = {
      selector: ".slider",
      easing: "cubic-bezier(0.55, 0.16, 0.59, 0.98)",
      duration: 600,
      loop: true,
      onInit: onInitCallback,
      onChange: onChangeCallback
    };

    const mySiema = (this.siema = new Siema(options));
    Siema.prototype.addPagination = function() {
      for (let i = 0; i < this.innerElements.length; i++) {
        const btn = document.createElement("span");

        const btnDiv = document.querySelectorAll(".pagination div");
        //btn.textContent = i;
        btn.setAttribute("class", "icon-cat" + i + " ");
        btn.addEventListener("click", () => this.goTo(i));

        btnDiv[i].appendChild(btn);
      }
    };

    mySiema.addPagination();

   // setInterval(() => mySiema.next(), 5000);
  }
}

class Process extends Home {
  render() {
    return (
      <section className="scrollspy" id="process">
        <div className="container">
          <h2 className="bolder">
            <Trans>Proces</Trans>,
          </h2>
          <h4>
            <Trans>który gwarantuje skuteczność</Trans>
          </h4>
          <div className="circle-list">
            <div className="circle">
              <h3>01</h3>
              <h6>
                <Trans>Definiujemy Cele</Trans>
              </h6>
              <p>
                <Trans>
                  Definiujemy cele kampanii, jej adresatów i najważniejsze
                  insighty.
                </Trans>
              </p>
            </div>
            <div className="circle">
              <h3>02</h3>
              <h6>
                <Trans>Targetujemy</Trans>
              </h6>
              <p>
                <Trans>Pokazujemy gdzie są WASI odbiorcy.</Trans>
              </p>
            </div>
            <div className="circle">
              <h3>03</h3>
              <h6>
                <Trans>Kreujemy</Trans>
              </h6>
              <p>
                <Trans>
                  Bazując na naszych doświadczeniach samodzielnie kreujemy
                  skuteczną formę materiału.
                </Trans>
              </p>
            </div>
            <div className="circle">
              <h3>04</h3>
              <h6>
                <Trans>Produkujemy</Trans>
              </h6>
              <p>
                <Trans>
                  Produkujemy wszystkie nawet najdziwniejsze formy. Nasze
                  materiały, pachną, mają różne faktury, zawierają próbki –
                  PRZEMAWIAJĄ DO ODBIORCY. Są dla niego. Nie dla każdego.
                </Trans>
              </p>
            </div>
            <div className="circle">
              <h3>05</h3>
              <h6>
                <Trans>Wdrażamy- Dystrybuujemy</Trans>
              </h6>
              <p>
                <Trans>
                  Bazując na sieci 100 wyselekcjonowanych Partnerów i
                  kooperantów.
                </Trans>
              </p>
            </div>
            <div className="circle">
              <h3>06</h3>
              <h6>
                <Trans>Ewaluujemy</Trans>
              </h6>
              <p>
                <Trans>
                  Liczy się nie tylko jakość dystrybucji, ale przede wszystkim
                  efekt kampanii.
                </Trans>
              </p>
            </div>
            <div className="circle">
              <h3>07</h3>
              <h6>
                <Trans>Generujemy Konwersję</Trans>
              </h6>
              <p>
                <Trans>
                  W mechanizmach staramy się zawsze zawrzeć konkretne CALL TO
                  ACTION, dzięki któremu możemy śledzić konwersję do ONLINE lub
                  do OFFLINE.
                </Trans>
              </p>
            </div>
          </div>
          <h4>
            <Trans>
              MOŻEMY TEŻ ZAWSZE SKUTECZNIE DYSTRYBUOWAĆ TWÓJ GOTOWY MATERIAŁ.
            </Trans>
          </h4>

          <h4 className="bolder">
            <Trans>
              W TARGETOWANEJ DYSTRYBUCJI JESTEŚMY JEDYNYM, SŁUSZNYM WYBOREM.
            </Trans>
          </h4>
        </div>
      </section>
    );
  }
}

class Counters extends Component {
  render() {
    return (
      <section className="scrollspy" id="counters">
        <div className="container">
          <div className="counters-list">
            <div>
              <span className="countup" cup-end="130">
                0
              </span>
              <p>
                <Trans>Magazynów regionalnych</Trans>
              </p>
            </div>
            <div>
              <span className="countup" cup-end="280">
                0
              </span>
              <p>
                <Trans>zadowolonych klientów</Trans>
              </p>
            </div>
            <div>
              <span className="countup" cup-end="52" cup-append=" 000">
                0
              </span>
              <p>
                <Trans>zrealizowanych zleceń</Trans>
              </p>
            </div>
            <div>
              <span>
                <Trans>ponad 1 miliard</Trans>
              </span>
              <p>
                <Trans>sztuk rozniesionych materiałów</Trans>
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }

  componentDidMount() {
    let i = 0;
    const cu = new counterUp({
      start: 0,
      duration: 1500,
      intvalues: true,
      interval: 15,

      append: ""
    });
    const counter = document.getElementById("counters");
    if (counter.length !== 0) {
      window.addEventListener("scroll", function() {
        const scrollPosition =
          document.documentElement.scrollTop || document.body.scrollTop;

        if (counter.offsetTop <= scrollPosition + 370 && i === 0) {
          cu.start();

          i = 1;
        }
      });
    }
  }
}

class Clients extends Component {
  prev = () => {
    this.siema.prev();
  };

  next = () => {
    this.siema.next();
  };

  render() {
    return (
      <section className="scrollspy" id="clients">
        <div className="container">
          <h2>
            <Trans>Zaufali nam</Trans>
          </h2>
          <div className="carousel">
            <a href="https://www.designeroutletwarszawa.pl/">
              <img src="./images/logo1.png" alt="" />
            </a>
            <a href="https://www.carrefour.pl/">
              <img src="./images/logo2.png" alt="" />
            </a>
            <a href="http://grupafachowiec.eu/">
              <img src="./images/logo3.png" alt="" />
            </a>
            <a href="https://www.norauto.pl/">
              <img src="./images/logo4.png" alt="" />
            </a>
            <a href="https://intermarche.pl/">
              <img src="./images/logo5.png" alt="" />
            </a>
            <a href="https://www.polomarket.pl/">
              <img src="./images/logo6.png" alt="" />
            </a>
            <a href="https://www.aliorbank.pl/">
              <img src="./images/logo7.png" alt="" />
            </a>
            <a href="http://www.rast.olsztyn.pl/">
              <img src="./images/logo8.png" alt="" />
            </a>
            <a href="https://www.ikea.com/pl/pl/">
              <img src="./images/logo9.png" alt="" />
            </a>
            <a href="https://www.castorama.pl/">
              <img src="./images/logo10.png" alt="" />
            </a>
            <a href="http://www.fabryka-formy.pl/">
              <img src="./images/logo11.png" alt="" />
            </a>
            <a href="http://www.biedronka.pl/pl">
              <img src="./images/logo12.png" alt="" />
            </a>
            <a href="https://www.flixbus.pl/">
              <img src="./images/logo13.png" alt="" />
            </a>
            <a href="https://www.e-sano.pl/">
              <img src="./images/logo14.png" alt="" />
            </a>
            <a href="https://www.c-and-a.com">
              <img src="./images/logo15.png" alt="" />
            </a>
            <a href="https://www.drogerienatura.pl">
              <img src="./images/logo16.png" alt="" />
            </a>
            <a href="https://papajohns.pl/">
              <img src="./images/logo17.png" alt="" />
            </a>
            <a href="https://www.leroymerlin.pl/">
              <img src="./images/logo18.png" alt="" />
            </a>
            <a href="http://twojmarket.pl/">
              <img src="./images/logo19.png" alt="" />
            </a>
            <a href="https://www.maxipizza.pl/">
              <img src="./images/logo20.png" alt="" />
            </a>
            <a href="https://jysk.pl/">
              <img src="./images/logo21.png" alt="" />
            </a>
            <a href="https://pizzahut.pl/">
              <img src="./images/logo22.png" alt="" />
            </a>
          </div>
          <span onClick={this.prev} className="icon-left" />

          <span onClick={this.next} className="icon-right" />
        </div>
      </section>
    );
  }

  componentDidMount() {
    const options = {
      selector: ".carousel",
      easing: "cubic-bezier(0.55, 0.16, 0.59, 0.98)",
      duration: 600,
      loop: true,
      perPage: {
        480: 2,
        768: 3,
        1024: 4
      }
    };

    this.siema = new Siema(options);
    setInterval(() => this.siema.next(), 2500);
  }
}

class CallToAction extends Component {
  render() {
    return (
      <section className="cta scrollspy" id="cta">
        <div className="container">
          <h3>
            <Trans>Chcesz rozwinąć swój biznes?</Trans>
          </h3>
          <NavLink className="btn" to="/contact">
            <Trans>Skontaktuj się z nami</Trans>
          </NavLink>
        </div>
      </section>
    );
  }
}

export default withTranslation(["translations"])(Home);


