import React, { Component } from "react";
import { withTranslation, Trans } from "react-i18next";

class Footer extends Component {
  render() {
    return (
      <footer className="scrollspy" id="footer">
        <div className="footer-wrap">
          <div className="container">
            <h4>
              <Trans>Napisz do nas</Trans>
            </h4>
            <div className="contact-wrap">
              <form
                action="https://formspree.io/kontakt@post-master.pl"
                method="POST"
              >
                <label>
                  <Trans>Imię nazwisko*</Trans>
                </label>
                <input type="text" name="name" required />
                <div className="group">
                  <div>
                    <label>
                      <Trans>Twój e-mail*</Trans>
                    </label>
                    <input type="email" name="_replyto" required />
                  </div>

                  <span>
                    <Trans>lub</Trans>
                  </span>
                  <div>
                    <label>
                      <Trans>Twój telefon*</Trans>
                    </label>
                    <input type="tel" name="phone" />
                  </div>
                </div>
                <label>
                  <Trans>Treść wiadomości</Trans>
                </label>
                <textarea name="message" />
                <div className="group">
                  <div className="checkbox">
                    <input type="checkbox" name="agree" id="check" required />
                    <label htmlFor="check">
                      <Trans>
                        Wyrażam zgodę na przetwarzanie moich danych osobowych w celu obsługi pytania. Potwierdzam, że zapoznałem się z klauzulą 
                      </Trans>
                    <a href="https://post-master.pl/RODO/" className="Rodo_link"><Trans> RODO.</Trans></a>
                    </label>
                  </div>

                  <button type="submit" className="btn">
                    <Trans>wyślij</Trans>
                    <input
                      type="hidden"
                      name="_next"
                      value="http://post-master.pl/"
                    />
                    <input type="hidden" name="_language" value="pl" />
                  </button>
                </div>
              </form>
              <div className="contact-data">
                <h3>
                  <span className="icon-phone" /> <a href="tel:124467829" data-tip data-for='telefon'>12 446 78 29</a>
                </h3>
                <p>PostMaster Sp. z o. o.</p> <p>ul. Rybitwy 22</p>
                <p>30-722 Kraków</p>
                <p>
                  <Trans>Znajdziesz nas na</Trans>
                </p>
                <a href="https://www.linkedin.com/company/post-master"
                  className="social"
                >
                  <span className="icon-linkedin_n" />
                </a>
                <a
                  href="https://www.facebook.com/PostMaster-1456642901253428/"
                  className="social"
                >
                  <span className="icon-fb_n" />
                </a>
              </div>
            </div>
            <div className="subfooter">
        <p>© {(new Date().getFullYear())} PostMaster All rights reserved</p>
        {/* 
              <p>
                created by
                <a href="http://openform.pl/"> Openform.pl</a>
              </p>
              */}
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default withTranslation("translations")(Footer);

