import i18n from "i18next";

import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    en: {
      translations: {
        "Strona główna":"Home",
        "Proces": "Process",
        "Doświadczenie": "Experience",
        "Zaufali nam": "They trust us",
        "Kontakt": "Contact",
        "Aktualności": "News",
        "slide1text1": "PostMaster <1 /> supports sales",
        "slide1text2": "A printed document has the selling power, <1 /> you just need to be able to discover it",
        "Tworzymy i wdrażamy rozwiązania, które realnie wspierają sprzedaż.": "We create and implement solutions that really support sales",
        "Myślimy strategicznie": "We think strategically",
        "Jesteśmy nie tylko ekspertami w dystrybucji, jesteśmy partnerem, który doradza jak najefektywniej wykorzystać ten kanał.": "We are not only experts in distribution, we are a partner who advises how to use this channel effectively",
        "Pokazujemy miejsce komunikacji offline w rozwiązaniach omnichanelowych.": "We show the place of offline communication in omnichannel solutions",
        "Kreujemy konwersję":"We create conversion",
        "Pracujemy z insightami Waszych klientów. Tworzymy kreatywną koncepcję kampanii. Opracowujemy mapy – geotargetujemy":"We work with your customers' insights. We prepare a creative campaign concept. We develop geotargeting ",
        "slide3text3":"We create conversion even at the rate of <1>43 %</1> - check us out!",
        "Nowa epoka w dystrybucji": "A new era in the distribution",
        "Rozwijamy system dokumentowania dystrybucji on-line, który umożliwia zarządzanie kolportażem w czasie rzeczywistym oraz bieżące monitorowanie jego jakości już w trakcie trwania procesu.": "We are developing an on-line distribution documentation system that allows you to manage your distribution in real time and to monitor its quality during the process",
        "slidebutton1text1": "Effectively<1 /> suport sales",
        "Odkrywamy na nowo potencjał komunikacji offline.": "Rediscovering an offline communication potential",
        "slidebutton2text1": "We think<1 /> strategically",
        "Tworzymy i dystrybuujemy materiały, realnie wzmacniające siłę Waszego przekazu.": "We both create and distribute materials that really strengthen the power of your media",
        "slidebutton3text1": "We create<1 /> conversion",
        "Na bazie wiedzy o tym DO KOGO? O CZYM? I W JAKIEJ FORMIE?": "Based on knowledge of to whom? What about? And in what form?",
        "slidebutton4text1": "A new era<1 /> in the distribution",
        "który gwarantuje skuteczność": "which guarantees the efficiency",
        "Definiujemy Cele": "We define goals",
        "Definiujemy cele kampanii, jej adresatów i najważniejsze insighty.": "We define capaign’s goals, its recipients, and most importantly insights",
        "Targetujemy": "Targeting",
        "Pokazujemy gdzie są WASI odbiorcy.": "We’re pointing out where your recipients are",
        "Kreujemy": "We create",
        "Bazując na naszych doświadczeniach samodzielnie kreujemy skuteczną formę materiału.": "Based on our experiences, we create an effective form of the material ourselves",
        "Produkujemy": "We produce",
        "Produkujemy wszystkie nawet najdziwniejsze formy. Nasze materiały, pachną, mają różne faktury, zawierają próbki – PRZEMAWIAJĄ DO ODBIORCY. Są dla niego. Nie dla każdego.": "We produce everything, even the most bizarre shapes. Our materials, smell, have different textures, contain sample – appeal to the recipient. The’re for one, not for everyone.",
        "Wdrażamy- Dystrybuujemy": "We Implement - We Distribute",
        "Bazując na sieci 100 wyselekcjonowanych Partnerów i kooperantów.": "Based on a network of 100 selected Partners and subcontractors",
        "Ewaluujemy": "Evaluate",
        "Liczy się nie tylko jakość dystrybucji, ale przede wszystkim efekt kampanii.": "Important is not only the quality of distribution, but first of all the effect of the campaign",
        "Generujemy Konwersję": "We generate conversion",
        "W mechanizmach staramy się zawsze zawrzeć konkretne CALL TO ACTION, dzięki któremu możemy śledzić konwersję do ONLINE lub do OFFLINE.": "In the mechanisms we always try to make a specific call to action, thanks to which we can track the conversion  to online or to offline",
        "MOŻEMY TEŻ ZAWSZE SKUTECZNIE DYSTRYBUOWAĆ TWÓJ GOTOWY MATERIAŁ.": "We also can effectively distribute yours prepared material",
        "W TARGETOWANEJ DYSTRYBUCJI JESTEŚMY JEDYNYM, SŁUSZNYM WYBOREM.": "In targeted distribution we’re the only and optimal choice",
        "Magazynów regionalnych": "Regional warehouses",
        "zadowolonych klientów": "Satisfied clients",
        "zrealizowanych zleceń": "Completed orders",
        "ponad 1 miliard": "over 1 Bilion",
        "sztuk rozniesionych materiałów": "Pieces of DELIVERED materials",
        "Chcesz rozwinąć swój biznes?": "Do you want to expand your business?",
        "Skontaktuj się z nami": "Contact us",
        "Napisz do nas": "Write to us",
        "Imię nazwisko*": "First and last name",
        "Twój e-mail*": "Your e-mail",
        "lub": "or",
        "Twój telefon*": "Your phone",
        "Treść wiadomości": "Message text",
        "Wyrażam zgodę na przetwarzanie moich danych osobowych w celu obsługi pytania. Potwierdzam, że zapoznałem się z klauzulą": "I express my consent to the processing of my personal data in order to service the issues. I have read the",
        "wyślij": "Send",
        "Znajdziesz nas na": "You can find us at",
        "projekt i realizacja": "Project and implementation",
        "Wiceprezes Zarządu": "Vice President",
        "Dyrektor Operacyjny": "Head of Operations",
        "Dyrektor Sprzedaży": "Head of Sales",
        "Województwa": "Provinces / Areas",
        "person4text1": "Regional<1 /> Sales Representative",
        "Godziny pracy naszego biura": "Office hours",
        "poniedziałek": "Monday",
        "wtorek": "Tuesday",
        "środa": "Wednesday",
        "czwartek": "Thursday",
        "piątek": "Friday",
        "PostMaster członkiem SMB":"PostMaster is a member of SMB",
        "Wspólnie odnawiamy rynek reklamy bezadresowej w Polsce!":"We're rebuild the non-addressed advertising market in Poland together!",
        "PostMaster bierze udział w akcji czysta klamka":"PostMaster take part in the clean handle action",
        "Wspólnie dbamy o dobro i bezpieczeństwo nas wszystkich!":"Together, we care about the good and safety of us all!",
        "Przepraszamy. Numer stacjonarny jest chwilowo nieosiągalny.":"We're sorry, but this phone number is temporarily unavailable.",
        "Czytaj więcej":"Read more"
      }
    }
  },
  fallbackLng: "pl",
  debug: false,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ","
  },

  react: {
    wait: true
  }
});

export default i18n;

